import React, { useEffect, useState } from 'react';
import { Layout, SEO, ProcessNav } from '@/components/index'
import { ProBanner, FadeInBox } from '@/new_components/index'
import { Header, Beampro_two, ThreeLineText, HighLights, Entertainment, Moment, Immersion, Guide, Choose, Specs, SaleBox, Use, Video3D } from '@/new_components/BeamPro'
import { buy_link } from '@/scripts/constant.js'
import getCountry from '@/utils/getCountry.js'
import { useInView } from "react-intersection-observer"
import BeamProLogo from '@/images/SVG/xreal_beampro_logo.svg'
import { entertainment_girl ,choose_beampro} from '@/images/beampro/index'
import { useIntl } from 'react-intl';
import { useGTMInit} from '@/hooks/useGTM.js'

const Index = () => {
    const intl = useIntl()
    const region = getCountry()
    const [probannerType, setProbannerType] = useState('overview')
    const specs_list = [
        {
            title: "beampro_block_specs_piece1_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece1_desc1"
                }
            ]
        },
        {
            title: "beampro_block_specs_piece2_title",
            desc: [
                {
                    title: "beampro_block_specs_piece2_desc2",
                    desc: "beampro_block_specs_piece2_desc1"
                }, {
                    title: "beampro_block_specs_piece2_desc4",
                    desc: "beampro_block_specs_piece2_desc3"
                }
            ]
        }, {
            title: "beampro_block_specs_piece3_title",
            desc: [
                {
                    title: "beampro_block_specs_piece3_desc1",
                    desc: "beampro_block_specs_piece3_desc2"
                },
                {
                    title: "beampro_block_specs_piece3_desc3",
                    desc: "beampro_block_specs_piece3_desc4"
                }, {
                    title: "beampro_block_specs_piece3_desc5",
                    desc: "beampro_block_specs_piece3_desc6"
                }
            ]
        }, {
            title: "beampro_block_specs_piece4_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece4_desc1"
                }
            ]
        }, {
            title: "beampro_block_specs_piece5_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece5_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece5_desc2"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece5_desc3"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece5_desc4"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece5_desc5"
                },
                {
                    title: "",
                    desc: "beampro_block_specs_piece5_desc6"
                }
            ]
        }, {
            title: "beampro_block_specs_piece6_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece6_desc1"
                }
            ]
        }, {
            title: "beampro_block_specs_piece7_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece7_desc1"
                }
            ]
        }, {
            title: "beampro_block_specs_piece8_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece8_desc1"
                }
            ]
        }, {
            title: "beampro_block_specs_piece9_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece9_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece9_desc2"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece9_desc3"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece9_desc4"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece9_desc5"
                }
            ]
        }, {
            title: "beampro_block_specs_piece10_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece10_desc1"
                }, {
                    title: "beampro_block_specs_piece10_desc2",
                    desc: "beampro_block_specs_piece10_desc3"
                }, {
                    title: "beampro_block_specs_piece10_desc4",
                    desc: "beampro_block_specs_piece10_desc5"
                }
            ]
        }, {
            title: "beampro_block_specs_piece11_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece11_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece11_desc2"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece11_desc3"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece11_desc4"
                }
            ]
        }, {
            title: "beampro_block_specs_piece12_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece12_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece12_desc2"
                }
            ]
        }, {
            title: "beampro_block_specs_piece13_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece13_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece13_desc2"
                }
            ]
        }, {
            title: "beampro_block_specs_piece14_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece14_desc1"
                }
            ]
        }, {
            title: "beampro_block_specs_piece15_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece15_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece15_desc2"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece15_desc3"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece15_desc4"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece15_desc5"
                }
            ]
        }, {
            title: "beampro_block_specs_piece16_title",
            desc: [
                {
                    title: "beampro_block_specs_piece16_desc1",
                    desc: "beampro_block_specs_piece16_desc2"
                }, {
                    title: "beampro_block_specs_piece16_desc3",
                    desc: [
                        "beampro_block_specs_piece16_desc4",
                        "beampro_block_specs_piece16_desc5",
                    ]
                }
            ]
        }, {
            title: "beampro_block_specs_piece17_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece17_desc1"
                }, {
                    title: "beampro_block_specs_piece17_desc2",
                    desc: "beampro_block_specs_piece17_desc3",
                }, {
                    title: "",
                    thick_title: [
                        "beampro_block_specs_piece17_desc4",
                        "beampro_block_specs_piece17_desc5",
                    ],
                    desc: "beampro_block_specs_piece17_desc6",
                }, {
                    title: "",
                    thick_title: [
                        "beampro_block_specs_piece17_desc7"
                    ],
                    desc: "beampro_block_specs_piece17_desc8"
                }, {
                    title: "",
                    thick_title: [
                        "beampro_block_specs_piece17_desc9"
                    ],
                    desc: "beampro_block_specs_piece17_desc10"
                }, {
                    title: "",
                    thick_title: [
                        "beampro_block_specs_piece17_desc11",
                        "beampro_block_specs_piece17_desc12",
                    ],
                    desc: "beampro_block_specs_piece17_desc13"
                }, {
                    title: "",
                    thick_title: [
                        "beampro_block_specs_piece17_desc14"
                    ],
                    desc: "beampro_block_specs_piece17_desc15"
                }, {
                    title: "",
                    thick_title: [
                        "beampro_block_specs_piece17_desc16"
                    ],
                    desc: "beampro_block_specs_piece17_desc17"
                }
            ]
        }, {
            title: "beampro_block_specs_piece18_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece18_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece18_desc2"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece18_desc3"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece18_desc4"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece18_desc5"
                },
            ]
        }, {
            title: "beampro_block_specs_piece19_title",
            desc: [
                {
                    title: "beampro_block_specs_piece19_desc1",
                    desc: "beampro_block_specs_piece19_desc2"
                }
            ]
        }, {
            title: "beampro_block_specs_piece20_title",
            desc: [
                {
                    title: "beampro_block_specs_piece20_desc1",
                    desc: "beampro_block_specs_piece19_desc2"
                }, {
                    title: "beampro_block_specs_piece20_desc3",
                    desc: [
                        "beampro_block_specs_piece20_desc4", "beampro_block_specs_piece20_desc5"
                    ]
                }
            ]
        }, {
            title: "beampro_block_specs_piece21_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece21_desc1"
                }
            ]
        }, {
            title: "beampro_block_specs_piece22_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece22_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece22_desc2"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece22_desc3"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece22_desc4"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece22_desc5"
                },
            ]
        }, {
            title: "beampro_block_specs_piece23_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece23_desc1"
                }
            ]
        },


    ]
    const specs_list_jp = [
        {
            title: "beampro_block_specs_piece1_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece1_desc1"
                }
            ]
        },
        {
            title: "beampro_block_specs_piece2_title",
            desc: [
                {
                    title: "beampro_block_specs_piece2_desc2",
                    desc: "beampro_block_specs_piece2_desc1"
                }, {
                    title: "beampro_block_specs_piece2_desc4",
                    desc: "beampro_block_specs_piece2_desc3"
                }, {
                    title: "beampro_block_specs_piece2_desc5",
                    desc: "beampro_block_specs_piece2_desc6"
                }, {
                    title: "beampro_block_specs_piece2_desc7",
                    desc: "beampro_block_specs_piece2_desc8"
                }, {
                    title: "beampro_block_specs_piece2_desc9",
                    desc: "beampro_block_specs_piece2_desc10"
                },
                , {
                    title: "",
                    desc: "beampro_block_specs_piece2_desc11"
                }
            ]
        }, {
            title: "beampro_block_specs_piece3_title",
            desc: [
                {
                    title: "beampro_block_specs_piece3_desc1",
                    desc: "beampro_block_specs_piece3_desc2"
                },
                {
                    title: "beampro_block_specs_piece3_desc3",
                    desc: "beampro_block_specs_piece3_desc4"
                }, {
                    title: "beampro_block_specs_piece3_desc5",
                    desc: "beampro_block_specs_piece3_desc6"
                }
            ]
        }, {
            title: "beampro_block_specs_piece4_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece4_desc1"
                }
            ]
        }, {
            title: "beampro_block_specs_piece5_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece5_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece5_desc2"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece5_desc3"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece5_desc4"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece5_desc5"
                },
                {
                    title: "",
                    desc: "beampro_block_specs_piece5_desc6"
                }
            ]
        }, {
            title: "beampro_block_specs_piece6_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece6_desc1"
                }
            ]
        }, {
            title: "beampro_block_specs_piece7_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece7_desc1"
                }
            ]
        }, {
            title: "beampro_block_specs_piece8_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece8_desc1"
                }
            ]
        }, {
            title: "beampro_block_specs_piece9_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece9_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece9_desc2"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece9_desc3"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece9_desc4"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece9_desc5"
                }
            ]
        }, {
            title: "beampro_block_specs_piece10_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece10_desc1"
                }, {
                    title: "beampro_block_specs_piece10_desc2",
                    desc: "beampro_block_specs_piece10_desc3"
                }, {
                    title: "beampro_block_specs_piece10_desc4",
                    desc: "beampro_block_specs_piece10_desc5"
                }
            ]
        }, {
            title: "beampro_block_specs_piece11_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece11_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece11_desc2"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece11_desc3"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece11_desc4"
                }
            ]
        }, {
            title: "beampro_block_specs_piece12_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece12_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece12_desc2"
                }
            ]
        }, {
            title: "beampro_block_specs_piece13_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece13_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece13_desc2"
                }
            ]
        }, {
            title: "beampro_block_specs_piece14_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece14_desc1"
                }
            ]
        }, {
            title: "beampro_block_specs_piece15_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece15_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece15_desc2"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece15_desc3"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece15_desc4"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece15_desc5"
                }
            ]
        }, {
            title: "beampro_block_specs_piece16_title",
            desc: [
                {
                    title: "beampro_block_specs_piece16_desc1",
                    desc: "beampro_block_specs_piece16_desc2"
                }, {
                    title: "beampro_block_specs_piece16_desc3",
                    desc: [
                        "beampro_block_specs_piece16_desc4",
                        "beampro_block_specs_piece16_desc5",
                    ]
                }
            ]
        }, {
            title: "beampro_block_specs_piece17_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece17_desc1"
                }, {
                    title: "beampro_block_specs_piece17_desc2",
                    desc: "beampro_block_specs_piece17_desc3",
                }, {
                    title: "",
                    thick_title: [
                        "beampro_block_specs_piece17_desc4",
                        "beampro_block_specs_piece17_desc5",
                    ],
                    desc: "beampro_block_specs_piece17_desc6",
                }, {
                    title: "",
                    thick_title: [
                        "beampro_block_specs_piece17_desc7"
                    ],
                    desc: "beampro_block_specs_piece17_desc8"
                }, {
                    title: "",
                    thick_title: [
                        "beampro_block_specs_piece17_desc9"
                    ],
                    desc: "beampro_block_specs_piece17_desc10"
                }, {
                    title: "",
                    thick_title: [
                        "beampro_block_specs_piece17_desc11",
                        "beampro_block_specs_piece17_desc12",
                    ],
                    desc: "beampro_block_specs_piece17_desc13"
                }, {
                    title: "",
                    thick_title: [
                        "beampro_block_specs_piece17_desc14"
                    ],
                    desc: "beampro_block_specs_piece17_desc15"
                }, {
                    title: "",
                    thick_title: [
                        "beampro_block_specs_piece17_desc16"
                    ],
                    desc: "beampro_block_specs_piece17_desc17"
                }
            ]
        }, {
            title: "beampro_block_specs_piece18_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece18_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece18_desc2"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece18_desc3"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece18_desc4"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece18_desc5"
                },
            ]
        }, {
            title: "beampro_block_specs_piece19_title",
            desc: [
                {
                    title: "beampro_block_specs_piece19_desc1",
                    desc: "beampro_block_specs_piece19_desc2"
                }
            ]
        }, {
            title: "beampro_block_specs_piece20_title",
            desc: [
                {
                    title: "beampro_block_specs_piece20_desc1",
                    desc: "beampro_block_specs_piece19_desc2"
                }, {
                    title: "beampro_block_specs_piece20_desc3",
                    desc: [
                        "beampro_block_specs_piece20_desc4", "beampro_block_specs_piece20_desc5"
                    ]
                }
            ]
        }, {
            title: "beampro_block_specs_piece21_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece21_desc1"
                }
            ]
        }, {
            title: "beampro_block_specs_piece22_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece22_desc1"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece22_desc2"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece22_desc3"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece22_desc4"
                }, {
                    title: "",
                    desc: "beampro_block_specs_piece22_desc5"
                },
            ]
        }, {
            title: "beampro_block_specs_piece23_title",
            desc: [
                {
                    title: "",
                    desc: "beampro_block_specs_piece23_desc1"
                }
            ]
        },


    ]
    const specs_list_show = region=='jp'?specs_list_jp:specs_list
    const banner_intro = {
        name: 'XREAL BEAM PRO',
        buy_link: buy_link[region]['beampro'].shop,
        logo: <BeamProLogo />
    }

    const [block1Ref, block1InView] = useInView({ threshold: 0 });
    const [block2Ref, block2InView] = useInView({ threshold: 0 });
    const navList = [
        {
            id: 0,
            title: intl.formatMessage({ id: "progress_beampro_0" }),
            classname: "beampro-progress-0",
        },
        {
            id: 1,
            title: intl.formatMessage({ id: "progress_beampro_1" }),
            classname: "beampro-progress-1",
        }, {
            id: 2,
            title: intl.formatMessage({ id: "progress_beampro_2" }),
            classname: "beampro-progress-2",
        }, {
            id: 3,
            title: intl.formatMessage({ id: "progress_beampro_3" }),
            classname: "beampro-progress-3",
        }, {
            id: 4,
            title: intl.formatMessage({ id: "progress_beampro_4" }),
            classname: "beampro-progress-4",
        }, {
            id: 5,
            title: intl.formatMessage({ id: "progress_beampro_5" }),
            classname: "beampro-progress-5",
        }

    ]
    useEffect(()=>{
        useGTMInit('products','XREAL Beam Pro')
    },[])
    return (
        <Layout menuBlack={block1InView || !block2InView ? true : false}>
            <SEO page="XREAL Beam Pro" />
            <div style={{ position: "relative" }}>
                <ProcessNav list={navList}  />
                {probannerType == 'overview' ? <>
                    <div ref={block1Ref}>
                        <div className='beampro-progress-0'>
                            <Header />
                            <Beampro_two />
                            <ThreeLineText />
                            <HighLights />
                            <FadeInBox backgroundImg={entertainment_girl} text={intl.formatMessage({ id: `beampro_part4_title` })} mobileImgPosition={'75%'} />
                        </div>
                        <div className='beampro-progress-1'>
                            <Entertainment />
                            <Use />
                        </div>


                    </div>
                    <div ref={block2Ref}>
                        <div className='beampro-progress-2'>
                            <Video3D />
                            <Moment />
                        </div>

                    </div>

                    <Immersion />

                    <div className='beampro-progress-5'>
                        <Guide setProbannerType={setProbannerType} />
                        {region !== 'th' && region !== 'kr' && <> <Choose /> </>}
                        {region == 'jp' && <SaleBox />}
                    </div>

                </> : <Specs list={specs_list_show} product={<BeamProLogo />} img={choose_beampro} />}


                <ProBanner banner_intro={banner_intro} setProbannerType={setProbannerType} probannerType={probannerType}/>

            </div>


        </Layout>
    )

}
export default Index