import React from "react";
import style from './index.module.less'

const Red_Button = (props) => {
    const { children, disabled, onClick, styles } = props

    return (
        <div className={style.rbutton} style={{ ...styles }} onClick={onClick} disabled={disabled}>
          {children}
        </div>
    )

}
const RedTextButton=(props) => {
    const { children, disabled, onClick, styles } = props

    return (
        <div className={style.rtbutton} style={{ ...styles }} onClick={onClick} disabled={disabled}>
          {children}
        </div>
    )

}

export {
    Red_Button,RedTextButton
}