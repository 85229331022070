import React from 'react'
import { useIntl } from 'react-intl'
import style from './index.module.less'
import {
    choose_beampro
} from '@/images/beampro/index'
import BeamProLogo from '@/images/SVG/xreal_beampro_logo.svg'
import getCountry from '@/utils/getCountry.js'
import classNames from 'classnames'

const Specs = ({list,ifBlack,product,img}) => {
    const intl = useIntl()
    const region = getCountry()
   

    return (
        <div className={style.box} style={{backgroundColor:!!ifBlack?'#111':'#fff'}}>
            <img src={img} />
            <div className={classNames(style.proName,ifBlack?style.whiteLogo : style.blackLogo)}>{product}</div>
            <div className={style.spec}>
                {list.map((item, index) => (
                    <div className={style.specItem} key={`beampro_spec_${index}`} style={{ alignItems: !item.desc.title && !item.desc.thick_title && item.desc.length == 1 ? 'center' : 'flex-start' ,borderTop:ifBlack?'0.5px solid rgba(255,255,255,0.2)':'0.5px solid #d9d9d9'}}>
                        <div className={style.specItemType} style={{color:ifBlack?'#fff':'#000'}}>
                            {intl.formatMessage({ id: item.title })}

                        </div>
                        <div className={style.specItemContent}>
                            {item.desc.map((value, key) => (
                                <div className={style.specItemContentValue} key={`beampro_spec_${index}_value${key}`} style={{ marginTop: !!value.title || !!value.thick_title ? '36px' : '8px' }}>
                                    {/* 是否有小标题 */}
                                    {!!value.title && <div className={style.specItemContentValueTitle} style={{color:ifBlack?'rgba(255,255,255, 0.40)':'rgba(0, 0, 0, 0.40)'}}>{intl.formatMessage({ id: value.title })}</div>}
                                    {/* 是否有加粗标题 */}
                                    {!!value.thick_title && value.thick_title.map((thickItem, thickIndex) => (
                                        <div className={style.specItemContentValueThick} key={`beampro_spec_${index}_value${key}_thickItem${thickIndex}`}
                                        style={{color:ifBlack?'#fff':'#000'}}
                                        >{intl.formatMessage({ id: thickItem })}</div>
                                    ))}

                                    {/* 描述是否有很多选项 还是只有一条 */}
                                    {Array.isArray(value.desc) ? value.desc.map((descItem, descIndex) => (
                                        <div className={style.specItemContentValueDesc} key={`beampro_spec_${index}_value${key}_desc${descIndex}`}
                                        style={{color:ifBlack?'#fff':'#000'}}
                                        >{intl.formatMessage({ id: descItem })}</div>
                                    )) : <div className={style.specItemContentValueDesc}
                                    style={{color:ifBlack?'#fff':'#000'}}
                                    >{intl.formatMessage({ id: value.desc })}</div>}


                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Specs